<template>
    <div>
        <div class="mb-4 d-flex">
            <b-button variant="light"
                      class="border w-40 h-40 mr-2"
                      :class="extraInfo ? null : 'collapsed'"
                      :aria-expanded="extraInfo ? 'true' : 'false'"
                      aria-controls="collapse-4"
                      @click="extraInfo = !extraInfo"
            >
                <i class="ri-arrow-down-s-line" v-if="!extraInfo"></i>
                <i class="ri-arrow-up-s-line" v-if="extraInfo"></i>
            </b-button>
            <div class="flex-grow-1 bg-light w-40 h-40 border rounded-sm d-flex align-items-center pl-3 font-weight-bold">
                {{ getObjectValue(doubleMajorApplication, 'student_number') }}, {{ getObjectValue(doubleMajorApplication, 'name') }}
            </div>
        </div>
        <b-collapse id="collapse-4" v-model="extraInfo">
            <div class="border rounded-sm p-4 mb-4">
                <b-row>
                    <b-col cols="auto">
                        <b-form-group :label="$t('faculty')">
                            <div class="label-as-input">
                                {{ getObjectValue(doubleMajorApplication, 'faculty_name') }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('program')">
                            <div class="label-as-input">
                                {{ getObjectValue(doubleMajorApplication, 'program_name') }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('class')">
                            <div class="label-as-input">
                                {{ doubleMajorApplication.class ? $t('class_x', {'class': doubleMajorApplication.class}) : '-' }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('number_of_semesters')">
                            <div class="label-as-input">
                                {{ getObjectValue(doubleMajorApplication, 'semester_count') }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('registration_type_1')">
                            <div class="label-as-input">
                                {{ doubleMajorApplication.registration_type_text || '-'}}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('registration_date')">
                            <div class="label-as-input">
                                {{ toLocaleDate(doubleMajorApplication.registration_date) }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('prep_status')">
                            <div class="label-as-input">
                                {{ doubleMajorApplication.student_program && doubleMajorApplication.student_program.prep_status || '-' }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('equivalence_status')">
                            <div class="label-as-input">
                                {{ doubleMajorApplication.student_program && doubleMajorApplication.student_program.equivalence_status || '-' }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group :label="$t('gno')">
                            <div class="label-as-input">
                                {{ getObjectValue(doubleMajorApplication, 'cgpa') }}
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
        </b-collapse>

        <div class="font-weight-medium mb-1">{{ $t('your_preferences') }}</div>
        <b-table :empty-filtered-text="$t('no_result')"
                 :empty-text="$t('no_result')"
                 bordered
                 responsive
                 :items="doubleMajorApplicationPreferences"
                 :fields="doubleMajorApplicationPreferenceFields"
                 show-empty
                 class="mb-4 table-dropdown no-scrollbar border rounded"
        >
            <template #cell(faculty_program)="row">
                {{ row.item.faculty_name }} <br>{{ row.item.program_name }}
            </template>
            <template #cell(status)="row">
                <span class="badge badge-warning" v-if="row.item.status.includes('waiting')">
                    {{ row.item.status_text }}
                </span>
                <div v-else-if="row.item.status == 'declined'">
                    <span class="badge badge-danger">
                        {{ row.item.status_text }}
                    </span>
                    <span v-if="row.item.explanation"><br>{{ row.item.explanation }}</span>
                </div>
                <span class="badge badge-success" v-else-if="row.item.status == 'approved'">
                    {{ row.item.status_text }}
                </span>
            </template>
            <template #cell(process)="row">
                <div class="d-flex justify-content-center"
                     v-if="row.item.status=='waiting'">
                    <b-button variant="primary"
                              size="sm"
                              @click="showStatusForm(row.item)">
                        {{ $t('edit') }}
                    </b-button>
                </div>
                <div class="text-center" v-else>
                    -
                </div>
            </template>
        </b-table>

        <CommonModal ref="statusFormModal" :onHideOnlyX="true"
                     @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>
                {{ $t('edit') }}
            </template>
            <template v-slot:CommonModalContent>
                <ValidationObserver ref="formModalValidate">
                    <b-row>
                        <b-col cols="12">
                            <ValidationProvider name="status" rules="required"  v-slot="{valid, errors}">
                                <b-form-group :label="$t('status')">
                                    <b-form-select v-model="formData.status"
                                                   :options="statusOptions"
                                                   :state="errors[0] ? false : null"
                                    />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                            <div v-show="formData.status=='declined'">
                                <ValidationProvider name="explanation" rules="required_if:status,declined" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('explanation')">
                                        <b-form-textarea v-model="formData.explanation"
                                                         :state="errors[0] ? false : null"
                                        />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div cols="12" v-show="formData.status=='approved'">
                                <ValidationProvider name="prep_school" rules="required_if:status,approved" v-slot="{valid, errors}">
                                    <b-form-group :label="'Hazırlık Okulu onayı?'">
                                        <b-form-select v-model="formData.prep_school"
                                                       :options="prepSchoolOptions"
                                                       :state="errors[0] ? false : null"
                                        />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                        </b-col>
                        <b-col cols="12" class="d-flex justify-content-center">
                            <b-button variant="primary" class="mt-0" @click="storeForm">
                                {{ $t('save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </template>
        </CommonModal>
    </div>
</template>

<script>
    // Component
    import CommonModal from '@/components/elements/CommonModal';

    // Services
    import DoubleMajorService from "@/services/DoubleMajorService";
    import DoubleMajorPreferenceService from "@/services/DoubleMajorPreferenceService";

    // Other
    import {ValidationProvider, ValidationObserver} from "vee-validate"

    export default {
        components: {
            CommonModal,

            ValidationProvider,
            ValidationObserver,
        },
        props: {
            doubleMajorApplication: {
                type: Object
            }
        },
        data() {
            return {
                formLoading: false,

                doubleMajorApplicationId: null,
                doubleMajorApplicationPreferences: [],
                doubleMajorApplicationPreferenceFields: [
                    {
                        key: 'rank',
                        label: this.$t('rank'),
                        tdClass: 'text-center align-middle',
                        thClass: 'text-center',
                        sortable: false
                    },
                    {
                        key: 'faculty_program',
                        label: this.$t('faculty'),
                        tdClass: 'align-middle',
                        sortable: false
                    },
                    {
                        key: 'status',
                        label: this.$t('status'),
                        tdClass: 'text-center align-middle',
                        thClass: 'text-center',
                        sortable: false
                    },
                    {
                        key: 'status',
                        label: this.$t('status'),
                        tdClass: 'align-middle',
                        sortable: false
                    },
                    {
                        key: 'prep_score',
                        label: this.$t('prep_score'),
                        tdClass: 'align-middle text-center',
                        thClass: 'text-center',
                        sortable: false
                    },
                    {
                        key: 'process',
                        label: this.$t('process'),
                        tdClass: 'align-middle',
                        thClass: 'text-center',
                        sortable: false
                    }
                ],

                formId: 0,
                formProcess: null,
                formData:{
                    double_major_preference_id: null,
                    status: null,
                    prep_school: null,
                    explanation: null,
                },
                statusOptions: [
                    { value: null, text: '-' },
                    { value: 'approved', text: this.$t('approved') },
                    { value: 'declined', text: this.$t('declined') },
                ],
                prepSchoolOptions: [
                    { value: null, text: this.$t('select') },
                    { value: 1, text: this.$t('yes') },
                    { value: 0, text: this.$t('no') },
                ],

                extraInfo: false
            }
        },
        created() {
            this.doubleMajorApplicationId = this.doubleMajorApplication.id;
            this.doubleMajorApplicationPreferences = this.doubleMajorApplication.double_major_preferences
            this.doubleMajorApplication.double_major_preferences.forEach((itm,index) => {
                this.doubleMajorApplicationPreferences[index].prep_score = this.doubleMajorApplication.prep_score
            })
        },
        methods: {
            async storeForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    this.formLoading = true;
                    let formData = {
                        double_major_preference_id : this.formId,
                        status: this.formData.status,
                        prep_school: this.formData.status=='approved' ? this.formData.prep_school:0,

                    };
                    if(this.formData.status=='declined'){
                        formData.explanation = this.formData.explanation
                    }
                    DoubleMajorPreferenceService.studentAffairsApprove(formData)
                                                     .then(() => {
                                                        this.getdoubleMajorApplication()
                                                        this.showStatusFormSuccess()
                                                        this.$emit('updated')
                                                     })
                                                     .catch(e => {
                                                         this.showErrors(e, this.$refs.formModalValidate);
                                                     })
                                                     .finally(() => {
                                                         this.formLoading = false
                                                     })

                }
            },
            getdoubleMajorApplication() {
                DoubleMajorService.get(this.doubleMajorApplicationId)
                                       .then(response => {
                                           let data = response.data.data;
                                           this.doubleMajorApplicationId = data.id;
                                           this.doubleMajorApplicationPreferences = data.double_major_preferences
                                       })
                                       .catch(e => {
                                           this.showErrors(e);
                                       })
            },

            formClear() {
                this.formId = null
                this.formData = {
                    double_major_preference_id: null,
                    status: null,
                    prep_school: null,
                    explanation: null,
                }
                this.formProcess = null
            },

            showStatusForm(row) {
                this.formId = row.id

                this.formProcess = 'status'
                this.$refs.statusFormModal.$refs.commonModal.show()
            },
            showStatusFormSuccess() {
                this.$refs.statusFormModal.$refs.commonModal.hide()
            }
        }

    }
</script>
